import { all, fork } from "redux-saga/effects";
import { watcherInsuranceUF } from "./InsuranceUF";
import { watcherInsuranceTypes } from "./InsuranceTypes";
import { watcherInsuranceByTypeInsurance } from "./InsuranceByTypeInsurance";
import { watcherInsuranceComparator } from "./InsuranceComparator";
import { watcherInsuranceInnerComparator } from "./InsuranceInnerComparator";

export default function* rootSaga() {
  yield all([
    fork(watcherInsuranceUF),
    fork(watcherInsuranceTypes),
    fork(watcherInsuranceByTypeInsurance),
    fork(watcherInsuranceComparator),
    fork(watcherInsuranceInnerComparator),
  ]);
}
