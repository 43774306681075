import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";

export function* watcherInsuranceInnerComparator() {
  yield takeLatest("INSURANCE_INNER_COMPARATOR_REQUEST", getInsuranceInnerComparator);
}

function fetchInsuranceInnerComparator({
  numPersonas,
  edadMaxima,
  vmMaximo,
  vmMinimo,
  strSeguros,
  sitio
}) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_URI_SERVER}/api/Public/Seguros/ComparaSeguro`,
    params: {
      numPersonas,
      edadMaxima,
      vmMaximo,
      vmMinimo,
      strSeguros,
      sitio:3
    },
  });
}

function* getInsuranceInnerComparator(action) {
  try {
    const response = yield call(fetchInsuranceInnerComparator, action.params);
    const data = response.data;
    yield put({ type: "INSURANCE_INNER_COMPARATOR_SUCCESS", data });
  } catch (error) {
    yield put({ type: "INSURANCE_INNER_COMPARATOR_FAILURE", error });
  }
}