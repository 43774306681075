import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";

export function* watcherInsuranceComparator() {
  yield takeLatest("INSURANCE_COMPARATOR_REQUEST", getInsuranceComparator);
}

function fetchInsuranceComparator({
  numPersonas,
  edadMaxima,
  vmMaximo,
  vmMinimo,
  strSeguros,
  sitio
}) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_URI_SERVER}/api/Public/Seguros/ComparaSeguro`,
    params: {
      numPersonas,
      edadMaxima,
      vmMaximo,
      vmMinimo,
      strSeguros,
      sitio:3
    },
  });
}

function* getInsuranceComparator(action) {
  try {
    const response = yield call(fetchInsuranceComparator, action.params);
    const data = response.data;
    yield put({ type: "INSURANCE_COMPARATOR_SUCCESS", data });
    // Resolvemos la promesa con el resultado
    if (action.meta?.resolve) {
      action.meta.resolve(response);
    }
  } catch (error) {
    yield put({ type: "INSURANCE_COMPARATOR_FAILURE", error });
    // Rechazamos la promesa en caso de error
    if (action.meta?.reject) {
      action.meta.reject(error);
    }
  }
}
