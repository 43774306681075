import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Styles/BeneficiosSeguro.css'
import beneficio01 from "../imagenes/BeneficiosCondiciones/beneficio01.jpg"

const BeneficiosSeguro = () => {
    const options = {
        loop: true,
        items: 3,
        margin: 20,
        nav: true,
        dots: false,
        responsive: {
            0: { items: 1 },
            576: { items: 2 },
            768: { items: 3 },            
          },     
      };

    return (  
        <div id="bc-old" className='mt-5 d-none'>
            <div className='container'>
                <h1 className="titulos-v1 text-center">Beneficios del seguro</h1>
                <OwlCarousel className="owl-theme beneficios-carousel" {...options}>
                    <div className="item item-beneficio">
                        <img src={beneficio01} alt="beneficios" className='img-fluid'/>
                    </div>
                    <div className="item item-beneficio">
                        <img src={beneficio01} alt="beneficios" className='img-fluid'/>
                    </div>
                    <div className="item item-beneficio">
                        <img src={beneficio01} alt="beneficios" className='img-fluid'/>
                    </div>
                    <div className="item item-beneficio">
                        <img src={beneficio01} alt="beneficios" className='img-fluid'/>
                    </div>
                    <div className="item item-beneficio">
                        <img src={beneficio01} alt="beneficios" className='img-fluid'/>
                    </div>
                </OwlCarousel>
            </div>
        </div>
    );
}
 
export default BeneficiosSeguro;
