import "../Styles/Planes.css";
import ReactTooltip from 'react-tooltip'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import React, {useEffect, useState, useRef} from 'react';
import { Button, Table } from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";
import CustomSpinner from "./CustomSpinner";
import { useSelector, useDispatch } from "react-redux";

const StyleModal={
  position:"absolute",
  top:"50%",
  left:"50%",
  transform:"translate(-50%,50%)"
}


function Comparador({ data, selectedPlans, numeroPersonas, numeroPersonasChange, edad, edadChange }) {
  const dispatch = useDispatch();
  const insuranceComparator = useSelector((state) => state.InsuranceComparator);
  const insuranceInnerComparator = useSelector((state) => state.InsuranceInnerComparator);
  const indiceMenorComparacionRef = useRef(null);
  
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const indexPlan = data.reduce((minIndex, actual, index, array) => {
    return actual.comparacion.length < array[minIndex].comparacion.length
      ? index
      : minIndex;
  }, 0);

  indiceMenorComparacionRef.current = indexPlan;
  // console.log(indiceMenorComparacionRef.current);

  const [estadoModal1,cambiarestadoModal1] = useState(false);
  const [estadoModal2,cambiarestadoModal2] = useState(false);
  const [estadoModal3,cambiarestadoModal3] = useState(false);
  
  const [numPeople, setNumPeople] = useState(numeroPersonas);
  const [maxAge, setMaxAge] = useState(edad);
  const [amounts] = useState([5000, 300000]);

  const onChangeNumPeopleChange = (value) => {
    setNumPeople(value);
    numeroPersonasChange(value); // cambiar personas al estado inicial
  };
  
  const onChangeMaxAgeChange = (value) => {
    setMaxAge(value);
    edadChange(value); // cambiar edad al estado inicial
  };
  
  const getPlansComparator = () => {
    console.log(selectedPlans.join());
    dispatch({
      type: "INSURANCE_COMPARATOR_REQUEST",
      params: {
        numPersonas: numPeople,
        edadMaxima: maxAge,
        vmMaximo: amounts[1],
        vmMinimo: amounts[0],
        strSeguros: selectedPlans.join(),
      },
    });
    //setSelectedPlans([]);
    //setIsDisableCompare(true);
  }

  
  return (
    <div className="comparacion-plan">
      <h4>Comparativo del seguro</h4>
      <div className="text-secondary">Indicar los siguientes datos</div>
      <div className="row my-5">
        <div className="col-sm-3">
          <label className="mb-2">Cantidad de beneficiarios</label>
          <CustomSpinner
            type={"int"}
            max={99}
            min={1}
            step={1}
            onChange={onChangeNumPeopleChange}
            size="sm"
            arrows={false}
            value={numPeople}
          />            
        </div>
        <div className="col-sm-3">
          <label className="mb-2">Edad de mayor del grupo</label>
          <CustomSpinner
            type={"int"}
            precision={2}
            max={74}
            min={18}
            step={1}
            onChange={onChangeMaxAgeChange}
            size="sm"
            value={maxAge}
          />
        </div>
        <div className="col-sm-3 align-self-end">
          <Button
            className="btn-comparar-plan inner"
            onClick={() => getPlansComparator()}              
          >
            {`Comparar `}
          </Button>
        </div>
      </div>      

      <Table responsive className="table-comparacion-planes">
        <thead>
          <tr className="ctr">
            <th></th>
            {data.map(({ nombreSeguro}) => {
              return (
                <td>                  
                  <div className="cnombreseguro">
                    {nombreSeguro}
                  </div>                
                </td>
              );
            })}
          </tr>          
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Precio desde</strong>
            </td>
            {data.map(({ valorSeguro, valorPrima, url}) => {
              return (
                <td className="text-center">                 
                  <h4 className="cvalorseguro">{`$${valorSeguro.toLocaleString("de-DE")}`}*</h4>
                  <h6 className="cvalorprima">{`UF ${valorPrima} mensual`}</h6>                                      
                </td>
              );
            })}
          </tr>
          {/* Iterar sobre el plan que tenga el arreglo de menor longitud para 'comparacion' */}
          {data[indiceMenorComparacionRef.current].comparacion.map(({caracteristica}, index) => {
            function ToolTipMaternidad(props) {
              const Etiqueta = props.Etiqueta;
              if (Etiqueta === "Maternidad") {
                return <i class="bi bi-info-circle icoinfo" data-html="true" data-tip="La fecha probable de inicio<br /> del embarazo debe ser<br /> posterior a la fecha de inicio<br /> de la vigencia de esta<br /> cobertura para el asegurado<br /> que se aplica." data-for={Etiqueta}></i>;
              }
              return "";
            }
            return (
              <tr>
                <td>                  
                  <strong>{caracteristica}</strong>
                  <ToolTipMaternidad Etiqueta={caracteristica} />
                </td>
                {data.map(({ comparacion }) => {

                  function CheckedCompare(props) {
                    const IsCheck = props.IsCheck;
                    const Check = props.Check;                    
                    const Informacion = props.Informacion;
                    const IsDeducible = props.Caracteristica === "Deducible" ? true : false;

                    if (IsCheck && Check) {  return <div className="cdivicon"><i className="bi bi-check-circle coicono active"></i></div>; }
                    if (IsCheck && !Check) {  return <i className="bi bi-check-circle coicono"></i>; }
                    if (!IsCheck && ((Informacion.trim()).length === 0)  ){  return <i className="bi bi bi-dash-circle coicono"></i>; }
                    if (IsDeducible && Informacion !== "Sin deducible") {  return ""; }
                    if (IsDeducible && Informacion === "Sin deducible") {  return <div className="cdivicon"><i className="bi bi-dash-circle coicono"></i></div>; }
                    return "";
                  }

                  function ShowInformacion(props) {
                    const Comentario = props.Comentario;
                    const Informacion = props.Informacion;
                    const IsDeducible = props.Caracteristica === "Deducible" ? true : false;
                    const IsCapitalAsegurado = props.Caracteristica === "Capital Asegurado" ? true : false;

                    if (Comentario === "c1") {  return <p>Tope en clínicas con <span className="cpclinicas"  onClick={()=>cambiarestadoModal1(!estadoModal1)}><u>Cobertura Preferente</u>, </span>{Informacion}</p> ; }
                    if (Comentario === "c2") {  return <p>Tope en clínicas con <span className="cpclinicas"  onClick={()=>cambiarestadoModal2(!estadoModal2)}><u>Cobertura Básica</u>, </span>{Informacion}</p> ; }
                    if (Comentario === "c3") {  return <p>Tope en clínicas con <span className="cpclinicas"  onClick={()=>cambiarestadoModal3(!estadoModal3)}><u>Cobertura Estándar</u>, </span>{Informacion}</p> ; }
                    if (IsDeducible && Informacion === "Sin deducible") { return ""; }
                    if ((IsDeducible && Informacion !== "Sin deducible") || IsCapitalAsegurado) { 
                      return <div className="mt-3" dangerouslySetInnerHTML={{__html: Informacion}} />; 
                    }
                    return "";                                       
                  }

                  return (
                    <td className="text-center py-4">
                      <ReactTooltip id={caracteristica} type="info" effect="solid">
                        {comparacion[index].comentario}                    
                      </ReactTooltip>
                      <Modal
                        isOpen={estadoModal1}
                        style={StyleModal}
                        >
                        <ModalHeader style={{justifyContent:"right"}}><i data-tip="React-tooltip" data-for="srtooltipComparator" style={{"fontSize" : "30px"}} class="bi bi-x-lg cpbtncerrar" onClick={()=>cambiarestadoModal1(false)}>
                          <ReactTooltip id="srtooltipComparator" place="right" type="info" effect="solid">
                            Volver
                          </ReactTooltip>
                          </i></ModalHeader>
                        <ModalBody>
                        <center>
                          <h1>Clínicas cobertura Preferente</h1>
                          <h5>Integramédica</h5>
                          <h5>Red Salud</h5>
                          <h5>Dávila</h5>
                        </center>
                        </ModalBody>
                        <center>
                          <Button className="mb-3 btndescargar" target="_blank" href="/files/clinica_cobertura_preferente.pdf" download >Descargar el listado completo</Button>                        
                        </center>
                      </Modal>
                      <Modal
                        isOpen={estadoModal2}
                        style={StyleModal}
                        >
                        <ModalHeader style={{justifyContent:"right"}}><i data-tip="React-tooltip" data-for="srtooltipComparator" style={{"fontSize" : "30px"}} class="bi bi-x-lg cpbtncerrar" onClick={()=>cambiarestadoModal2(false)}>
                          <ReactTooltip id="srtooltipComparator" place="right" type="info" effect="solid">
                            Volver
                          </ReactTooltip>
                          </i></ModalHeader>
                        <ModalBody>
                        <center>
                          <h1>Clínicas cobertura Básica</h1>
                          <h5>1. Clínica Alemana de Santiago</h5>
                          <h5>2. Clínica Las Condes</h5>
                          <h5>3. Clínica San Carlos de Apoquindo Red Salud UC Christus</h5>
                          <h5>4. Hospital Clínico Universidad de los Andes</h5>
                          <h5>5. Centro de la Visión Clínica Las Condes</h5>
                        </center>
                        </ModalBody>
                      </Modal>
                      <Modal
                        isOpen={estadoModal3}
                        style={StyleModal}
                        >
                        <ModalHeader style={{justifyContent:"right"}}><i data-tip="React-tooltip" data-for="srtooltipComparator" style={{"fontSize" : "30px"}} class="bi bi-x-lg cpbtncerrar" onClick={()=>cambiarestadoModal3(false)}>
                          <ReactTooltip id="srtooltipComparator" place="right" type="info" effect="solid">
                            Volver
                          </ReactTooltip>
                          </i></ModalHeader>
                        <ModalBody>
                        <center>
                          <h1>Clínicas cobertura Estándar</h1>
                          <h5>Santa María</h5>
                          <h5>Indisa</h5>
                          <h5>Clínica Alemana de Valdivia</h5>
                          <br></br>
                        </center>
                        </ModalBody>
                        <ModalFooter style={{background: "#cce9f7"}} >
                          <center>
                          <h4>Y cualquier otra que no aparezca como Preferente ni Básica.</h4>
                          </center>
                        </ModalFooter>                                                                             
                      </Modal>
                      <CheckedCompare 
                        Caracteristica={comparacion[index].caracteristica} 
                        Informacion={comparacion[index].informacion} 
                        IsCheck={comparacion[index].isCheck} 
                        Check={comparacion[index].check}
                      />
                      <ShowInformacion 
                        Caracteristica={comparacion[index].caracteristica} 
                        Comentario={comparacion[index].comentario} 
                        Informacion={comparacion[index].informacion}
                        Orden={comparacion[index].orden}
                      />                      
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>      
  );

}
export default Comparador;