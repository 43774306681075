import "../Styles/Planes.css";
import React, {useEffect, useState} from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faDownload } from '@fortawesome/free-solid-svg-icons';
import logo from "../imagenes/Home/INDISA.svg"
import logomet from "../imagenes/Home/logo_MetLife.png"


function DetallePlan({ data, selectedPlans }) {  
  
  const [isOpenValor, setIsOpenValor] = useState(false);
  const [isOpenDeducible, setIsOpenDeducible] = useState(false);
  const [isOpenCapital, setIsOpenCapital] = useState(false);
  const [isOpenCobertura, setIsOpenCobertura] = useState(false);
  const [openOrden, setOpenOrden] = useState([false,false,false,false,false,false,false,false,false,false]);

  const toggleAccordionValor = () => setIsOpenValor(!isOpenValor);
  const toggleAccordionDeducible = () => setIsOpenDeducible(!isOpenDeducible);
  const toggleAccordionCapital = () => setIsOpenCapital(!isOpenCapital);
  const toggleAccordionCobertura = () => setIsOpenCobertura(!isOpenCobertura);
  const toggleAccordionOrden = (orden) => {
    const newOpenOrden = openOrden.map((isOpen, i) =>
      i === orden ? !isOpen : isOpen
    );
    setOpenOrden(newOpenOrden);
  };
  

  return (
    <div className="detalle-plan">
      <div className="d-flex justify-content-start align-items-center">
        <a href="https://www.indisa.cl/seguros-y-convenios/">
          <img src={logo} alt="logo Indisa" style={{ width:'96px'}}/>
        </a>
        <img src={logomet} alt="logo Metlife" style={{ marginLeft: 20, width:'144px'}}/>
      </div>
      <div className="detalle-plan_header">
        <p>Coberturas</p>
        <h4><strong>{data.nombreSeguro}</strong></h4>
        <br />
        <div>Desde</div>
        <h4>{`${data.valorPrima} UF / $${data.valorSeguro.toLocaleString("de-DE")}`}</h4>
        <div>Mensual por asegurado</div>
      </div>
      <div className="text-center">
        <a 
          target="_blank" 
          rel="noreferrer" 
          href={data.url} 
          className="btn btn-comprar-plan"
          style={{ width:'160px', display:'inline-block' }}
        >
          Comprar
        </a>
      </div>
      <p>{data.capitalAseguradoComentario}</p>
      <a href={`/files/Seguro${data.idSeguro}.pdf`} target="_blank" rel="noreferrer" className="btn btn-ficha-plan">
        Ficha del producto
        <FontAwesomeIcon icon={faDownload} />
      </a>

      <Accordion defaultActiveKey="0" className='accordion-plan mt-3'>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={toggleAccordionValor}>
              Valor
              <FontAwesomeIcon icon={!isOpenValor ? faChevronUp : faChevronDown} />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              El costo mensual de este seguro es de ${data.valorSeguro.toLocaleString("de-DE")} mensual, lo que equivale a UF {data.valorPrima}.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion defaultActiveKey="0" className='accordion-plan mt-3'>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={toggleAccordionDeducible}>
              Deducible
              <FontAwesomeIcon icon={!isOpenDeducible ? faChevronUp : faChevronDown} />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {data.deducibleAclaracion === 'Sin' ? (
                <>No tiene deducible, lo que significa que no deberás pagar un monto inicial antes de que el seguro comience a cubrir los gastos.</>
              ) : (
                <>{data.deducibleAclaracion} {data.deducible}</>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion defaultActiveKey="0" className='accordion-plan mt-3'>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={toggleAccordionCapital}>
              Capital Asegurado
              <FontAwesomeIcon icon={!isOpenCapital ? faChevronUp : faChevronDown} />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              El capital asegurado es de {data.capitalAsegurado}. Esto establece un límite máximo para ciertos beneficios, pero no afecta los reembolsos porcentuales.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion defaultActiveKey="0" className='accordion-plan mt-3'>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={toggleAccordionCobertura}>
              Cobertura
              <FontAwesomeIcon icon={!isOpenCobertura ? faChevronUp : faChevronDown} />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div dangerouslySetInnerHTML={{ __html: data.cobertura }} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      {data.comparacion.flatMap((comp, index) => comp.orden > 0 ? 
        (
        <Accordion defaultActiveKey="0" className='accordion-plan mt-3'>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => toggleAccordionOrden(comp.orden)}>
                {comp.caracteristica}
                <FontAwesomeIcon icon={!openOrden[comp.orden] ? faChevronUp : faChevronDown} />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {comp.informacion}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        )
        : 
        []
      )}     

    </div>
  );

}
export default DetallePlan;