import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

const BotonAsesorame = () => {
  return (
    <a 
      href="https://segurosenlinea.metlife.cl/TestFormulario/VentaWeb/Formulario/Form_Contactabilidad" 
      target="_blank" 
      rel="noopener noreferrer" 
      className="btn-asesorame"
      style={{
        position: 'fixed',
        bottom: '10px',
        left: '15px',
        padding: '10px 30px',
        backgroundColor: '#0061A0',
        color: '#fff',
        textDecoration: 'none',
        borderRadius: '20px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        fontSize: '16px',
        zIndex: 1000, // Para mantenerlo encima de otros elementos
      }}
    >
        <FontAwesomeIcon icon={faPhoneAlt} style={{ marginRight: '8px'}}/> Asesórenme
    </a>
  );
}

export default BotonAsesorame