import React, { useState } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import '../Styles/Condiciones.css';

const Condiciones = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleSelect = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };

  return (
    <div id="bc" className='mt-5'>
      <div className='container'>
        <h1 className="titulos-v1 text-center">¿Tienes dudas sobre los seguros de salud?</h1>
        <h5 className='text-center'>Con estas preguntas frecuentes te orientamos para que tomes la mejor decisión.</h5>
        <Accordion activeKey={activeKey} onSelect={handleSelect} defaultActiveKey="0" className='accordion-beneficios mt-5'>
          {/* Primer Acordeón */}
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => handleSelect("0")}>
                ¿Qué son y cómo funcionan?
                <FontAwesomeIcon
                  icon={activeKey === "0" ? faChevronUp : faChevronDown}
                  className=""
                />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>                            
                {/* Acordeón anidado */}
                <Accordion className='nested-accordion-beneficios'>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0-1">
                        ¿Qué es un seguro complementario de salud?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0-1">
                      <Card.Body>
                        Un seguro complementario de salud es un contrato que te brinda cobertura adicional a la que ya tienes con tu Isapre o Fonasa, ampliando los beneficios y reduciendo tus gastos médicos.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0-2">
                        ¿Por qué necesito un seguro complementario?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0-2">
                      <Card.Body>
                        Para acceder a una mayor gama de servicios médicos, reducir los costos de bolsillo, obtener coberturas más completas y tener mayor tranquilidad ante imprevistos de salud.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0-3">
                        ¿Cómo funciona un seguro complementario?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0-3">
                      <Card.Body>
                        Al contratar un seguro complementario, pagas una cuota mensual y, a cambio, tienes acceso a los servicios y beneficios especificados en el contrato.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0-4">
                        ¿Qué diferencia hay entre un seguro complementario y un sistema de salud?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0-4">
                      <Card.Body>
                        El sistema de salud (Isapre o Fonasa) cubre una serie de prestaciones básicas, mientras que el complementario amplía esas coberturas y agrega beneficios adicionales.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          {/* Segundo Acordeón */}
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={() => handleSelect("1")}>
                Contratación y Uso
                <FontAwesomeIcon
                  icon={activeKey === "1" ? faChevronUp : faChevronDown}
                  className=""
                />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                {/* Acordeón anidado dentro del acordeón 2 */}
                <Accordion className='nested-accordion-beneficios'>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1-1">
                        ¿Cómo contrato un seguro complementario?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1-1">
                      <Card.Body>
                        Puedes contratarlo directamente con la compañía aseguradora, a través de un corredor de seguros o por internet.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1-2">
                        ¿Qué documentos necesito para contratar un seguro complementario?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1-2">
                      <Card.Body>
                        <ul>
                          <li>Cédula de identidad</li>
                          <li>Comprobante de domicilio</li>
                          <li>Carnet de afiliado a Isapre o Fonasa</li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1-3">
                        ¿Cómo utilizo mi seguro complementario?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1-3">
                      <Card.Body>
                        Debes presentar tu carnet de seguro al prestador de salud y seguir los procedimientos establecidos por la aseguradora.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1-4">
                        ¿Qué pasa si necesito atención médica fuera de la ciudad?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1-4">
                      <Card.Body>
                        La mayoría de los seguros complementarios ofrecen cobertura nacional, pero es importante verificar las condiciones específicas.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          {/* Tercer Acordeón */}
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="2" onClick={() => handleSelect("2")}>
                Coberturas y Beneficios
                <FontAwesomeIcon
                  icon={activeKey === "2" ? faChevronUp : faChevronDown}
                  className=""
                />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                {/* Acordeón anidado dentro del acordeón 3 */}
                <Accordion className='nested-accordion-beneficios'>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="2-1">
                        ¿Qué tipo de coberturas ofrecen los seguros complementarios?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2-1">
                      <Card.Body>
                        Coberturas hospitalarias, ambulatorias, odontológicas, oftalmológicas, maternidad, enfermedades catastróficas, accidentes personales, entre otras.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="2-2">
                        ¿Qué es una cirugía preferente?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2-2">
                      <Card.Body>
                        Son cirugías que tienen una mayor cobertura o beneficios adicionales dentro del plan.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="2-3">
                        ¿Qué es un deducible?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2-3">
                      <Card.Body>
                        Es un monto fijo que debes pagar tú antes de que la aseguradora comience a cubrir los gastos médicos.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="2-4">
                        ¿Qué es un copago?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2-4">
                      <Card.Body>
                        Es un porcentaje del costo total de un servicio médico que debes pagar tú.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          {/* Cuarto Acordeón */}
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="3" onClick={() => handleSelect("3")}>
                Costos y Renovación
                <FontAwesomeIcon
                  icon={activeKey === "3" ? faChevronUp : faChevronDown}
                  className=""
                />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                {/* Acordeón anidado dentro del acordeón 4 */}
                <Accordion className='nested-accordion-beneficios'>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="3-1">
                        ¿Cuánto cuesta un seguro complementario?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3-1">
                      <Card.Body>
                        El costo varía según la compañía aseguradora, el plan elegido, la edad y las coberturas contratadas.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="3-2">
                        ¿Cómo se calcula la prima de un seguro complementario?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3-2">
                      <Card.Body>
                        La prima se calcula en función de diversos factores, como edad, sexo, historial médico y coberturas contratadas.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="3-3">
                        ¿Puedo cancelar mi seguro complementario?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3-3">
                      <Card.Body>
                        Sí, puedes cancelar tu seguro complementario bajo ciertas condiciones, generalmente notificando a la aseguradora con un plazo de anticipación.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="3-4">
                        ¿Cómo se renueva un seguro complementario?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3-4">
                      <Card.Body>
                        La renovación suele ser automática, pero puedes realizar cambios en tu plan si lo deseas.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  )
}

export default Condiciones