import '../Styles/Banner.css'
import banner from "../imagenes/Banner/banner01.jpg"

const Banner = () => {
    return (
        <div id="banner" className='mx-2rem'>
            <img src={banner} alt="banner" className='img-fluid'/>         
        </div>
    )

}

export default Banner