import "../Styles/Planes.css";
import { IconCheckbox } from "react-icon-checkbox";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as faCircleSolid } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffects } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Accordion, Card, Button } from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";

const MAX_SELECTABLE = 3;
const DEFAULT_CARDS_TO_SHOW = 3;

const Planes = ({ data, click, index, watchAll, selectedList, numPeople, maxAge, amounts, onClickVerDetalle}) => {
  const dispatch = useDispatch();
  const {
    idSeguro,
    nombreSeguro,
    cobertura,
    valorSeguro,
    valorPrima,
    url,
    clinica1,
  } = data;

  
  const [value, setValue] = useState(false);
  const onChangeValue = () => {
    if (selectedList.length < MAX_SELECTABLE) {
      click(idSeguro, !value);
      setValue(!value);
    } else {
      if (value) {
        click(idSeguro, !value);
        setValue(!value);
      }
    }
    console.log(selectedList);
  };

  const [loading, setLoading] = useState(false);
  const handleClickVerDetalle = async () => {
    setLoading(true);
    await onClickVerDetalle(idSeguro);
    setLoading(false);
  };
  
  
  /* Para ventana modal de bootstrap */
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [activeKey, setActiveKey] = useState("0");
  const toggleAccordion = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };


  // if (index > DEFAULT_CARDS_TO_SHOW && !watchAll) {
  //   return null;
  // }
  
  return (
    <>
      {clinica1 === "card-seguro51" ? (
        <div className={"text-white box-new " + clinica1}>
          <div className="text-center"><b>¡Nuevo Seguro!</b></div>
        </div>
      ) : (
        <div>&nbsp;</div>
      )}
      <div className="card" id={`card-plan-${idSeguro}`}>
        <div className={`card-header ${clinica1}`}>{nombreSeguro}</div>
        <div className="card-body">
          <div className='card-title'>
            Desde ${valorSeguro.toLocaleString("de-DE")} / UF {valorPrima}
          </div>
          <div className='card-text'>
            <div className='text-center mb-3'><strong>Coberturas</strong></div>
            <div dangerouslySetInnerHTML={{ __html: cobertura }} />
          </div>
          <a 
            target="_blank" 
            rel="noreferrer" 
            href={url} 
            className="btn btn-comprar-plan"
          >
            Comprar
          </a>          
          <button
            id={`detalle${idSeguro}`}
            type="button"
            className="btn btn-detalle-plan"            
            onClick={handleClickVerDetalle}
            disabled={loading}
          >
            {`Ver detalle `}                       
            {loading && (              
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button><br/>          
          
          <div className="text-center">
            <IconCheckbox
              checked={value}
              checkedIcon={
              <div className="psdivcomparar">
                <FontAwesomeIcon icon={faCircleSolid} /> Selecciona para comparar              
              </div>
              }
              uncheckedIcon={
              <div className="pdivcomparar">
                <FontAwesomeIcon icon={faCircle} /> Selecciona para comparar  
              </div>
              }
              onClick={onChangeValue}
            />
          </div>
          
          {/* <button
            type="button"
            className="btn btn-detalle-plan"
            onClick={toggle}                     
          >
            Ver detalle
          </button>

          <Modal isOpen={modal} toggle={toggle} size="xl" centered className="modal-detalle-plan">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
            <div className="detalle-plan">
              <div className="detalle-plan_header">
                <p>Coberturas</p>
                <h4><strong>{nombreSeguro}</strong></h4>
                <br />
                <div>Desde</div>
                <h4>{`${valorPrima} UF / $${valorSeguro.toLocaleString("de-DE")}`}</h4>
                <div>Mensual por asegurado</div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              <a 
                target="_blank" 
                rel="noreferrer" 
                href="#"
                className="btn btn-ficha-plan"
              >
                Ficha del producto
              </a>
              
              <Accordion activeKey={activeKey} className='accordion-beneficios mt-5'>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="0"
                      onClick={() => toggleAccordion("0")}
                    >
                      Valor
                      <FontAwesomeIcon
                        icon={activeKey === "0" ? faChevronUp : faChevronDown}
                        className=""
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>Contenido de la sección 1. Aquí puedes agregar texto o componentes adicionales.</Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="1"
                      onClick={() => toggleAccordion("1")}
                    >
                      Deducible
                      <FontAwesomeIcon
                        icon={activeKey === "1" ? faChevronUp : faChevronDown}
                        className=""
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>Contenido de la sección 2.</Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

            </div>
            </ModalBody>
          </Modal> */}
          
        </div>
      </div>
    </>
  );
};
export default Planes;
