import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import '../Styles/ComoFuncionaSeguro.css'

const ComoFuncionaSeguro = () => {
    return (  
        <div id="cf">
            <div className="container">
                <h1 className="titulos-v1 text-center">¿Cómo funcionan los seguros?</h1>
                <Tab.Container defaultActiveKey="ambulatorios">
                    <Nav variant="tabs" className='tabs-cf'>
                        <Nav.Item>
                            <Nav.Link eventKey="ambulatorios">Ambulatorios</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="complementarios">Complementarios</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="catastroficos">Catastróficos</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className='tab-content-cf'>
                        <Tab.Pane eventKey="ambulatorios">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h4>¿Cómo funciona tu seguro Ambulatorio?</h4>
                                    <p>Imaginemos que necesitas un chequeo médico completo y acudes a una clínica. El costo total de la consulta y los exámenes suma $50.000. Con un seguro de salud, el proceso es más sencillo. Si tienes Fonasa o Isapre, estos cubrirán un porcentaje, por ejemplo, el 60%, lo que equivale a $30.000. El monto restante, $20.000, es donde entra tu seguro complementario. Si tu seguro te reembolsa el 60% del copago, te devolverán $18.000. Al final, solo pagarías $12.000, gracias a la combinación de coberturas de tu sistema previsional y tu seguro complementario. Esto demuestra cómo los seguros de salud te ayudan a reducir significativamente los costos médicos, brindándote mayor tranquilidad y acceso a una mejor atención.</p>
                                </div>
                                <div className='col-md-6'>
                                    <div className='table-cf-wrapper'>
                                        <table className="table- table-cf">
                                            <tbody>
                                                <tr>
                                                    <td>Consulta médica</td>
                                                    <td>$50.000</td>                                            
                                                </tr>
                                                <tr>
                                                    <td><span>Fonasa 60%</span></td>
                                                    <td className='text-green'>-$30.000</td>                                            
                                                </tr>
                                                <tr className='line'>
                                                    <td>Total sin seguro</td>
                                                    <td className='monto-destacado'><span>$20.000</span></td>                                            
                                                </tr>
                                                <tr>
                                                    <td><span>Seguro <br/>complementario 60%</span></td>
                                                    <td className='text-green'>-$18.000</td>                                            
                                                </tr>
                                                <tr className='line'>
                                                    <td>Pagarás</td>
                                                    <td>$12.000</td>                                            
                                                </tr>
                                                <tr>
                                                    <td>Ahorraste</td>
                                                    <td className='monto-destacado'>
                                                        <span className='ahorraste'>$38.000</span>
                                                    </td>                                            
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>                                                        
                        </Tab.Pane>
                        <Tab.Pane eventKey="complementarios">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h4>¿Cómo funciona tu seguro Complementario?</h4>
                                    <p>Imagina que te hospitalizan por apendicitis en la Clínica INDISA. El costo total de tu estadía y los procedimientos médicos asciende a $3.500.000. Si tienes un sistema previsional como Fonasa o Isapre, este cubrirá una parte de esos gastos. Por ejemplo, si tu Isapre cubre el 60%, te reembolsarán $2.100.000. Sin embargo, aún te quedaría un saldo pendiente de $1.400.000. Aquí es donde entra en juego tu seguro complementario. Si este seguro te ofrece un reembolso del 50% del copago, te devolverán $700.000.</p>
                                </div>
                                <div className='col-md-6'>
                                    <div className='table-cf-wrapper'>
                                        <table className="table- table-cf">
                                            <tbody>
                                                <tr>
                                                    <td>Consulta médica</td>
                                                    <td>$50.000</td>                                            
                                                </tr>
                                                <tr>
                                                    <td><span>Fonasa 60%</span></td>
                                                    <td className='text-green'>-$30.000</td>                                            
                                                </tr>
                                                <tr className='line'>
                                                    <td>Total sin seguro</td>
                                                    <td className='monto-destacado'><span>$20.000</span></td>                                            
                                                </tr>
                                                <tr>
                                                    <td><span>Seguro <br/>complementario 60%</span></td>
                                                    <td className='text-green'>-$18.000</td>                                            
                                                </tr>
                                                <tr className='line'>
                                                    <td>Pagarás</td>
                                                    <td>$12.000</td>                                            
                                                </tr>
                                                <tr>
                                                    <td>Ahorraste</td>
                                                    <td className='monto-destacado'>
                                                        <span className='ahorraste'>$38.000</span>
                                                    </td>                                            
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="catastroficos">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h4>¿Cómo funciona tu seguro Catastrófico?</h4>
                                    <p>Imagina que sientes un fuerte dolor abdominal y decides ir a urgencias en la Clínica INDISA. Allí te diagnostican pancreatitis aguda. El tratamiento y los exámenes necesarios suman un total de $20.500.000. Con un seguro de salud, una parte considerable de este costo será cubierto. Tu Isapre o Fonasa se encargará de pagar un porcentaje, dejando un saldo pendiente. Sin embargo, tu seguro complementario cubrirá gran parte de ese saldo restante. Además, durante los próximos 3 años, tu seguro seguirá cubriendo gastos relacionados con este diagnóstico específico, brindándote tranquilidad y asegurando una atención médica completa.</p>
                                </div>
                                <div className='col-md-6'>
                                    <div className='table-cf-wrapper'>
                                        <table className="table- table-cf">
                                            <tbody>
                                                <tr>
                                                    <td>Consulta médica</td>
                                                    <td>$50.000</td>                                            
                                                </tr>
                                                <tr>
                                                    <td><span>Fonasa 60%</span></td>
                                                    <td className='text-green'>-$30.000</td>                                            
                                                </tr>
                                                <tr className='line'>
                                                    <td>Total sin seguro</td>
                                                    <td className='monto-destacado'><span>$20.000</span></td>                                            
                                                </tr>
                                                <tr>
                                                    <td><span>Seguro <br/>complementario 60%</span></td>
                                                    <td className='text-green'>-$18.000</td>                                            
                                                </tr>
                                                <tr className='line'>
                                                    <td>Pagarás</td>
                                                    <td>$12.000</td>                                            
                                                </tr>
                                                <tr>
                                                    <td>Ahorraste</td>
                                                    <td className='monto-destacado'>
                                                        <span className='ahorraste'>$38.000</span>
                                                    </td>                                            
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
}
 
export default ComoFuncionaSeguro;