import '../Styles/Inicio.css'
import logo from "../imagenes/Home/INDISA.svg"
import logomet from "../imagenes/Home/logo_MetLife.png"
import { Nav, Navbar, Button } from 'react-bootstrap'

const Inicio = () => {
    return (
      <div id="header">
        <Navbar expand="lg" className="navbar fixed-top">
          <Navbar.Brand href="#">
            <div id="ilogo" className="animate__animated animate__bounce">
              <a href="https://www.indisa.cl/seguros-y-convenios/"><img src={logo} alt="Indisa"/></a>
              <img style={{ marginLeft: 20, width:'150%'}} src={logomet} alt=""/>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="m-auto my-2 my-lg-0 inav animate__animated animate__bounce"
              navbarScroll
            >
              <Nav.Link href="#sr">Nuestros planes</Nav.Link>
              <Nav.Link href="#cf">¿Cómo funciona?</Nav.Link>
              <Nav.Link href="#bc">Beneficios del seguro</Nav.Link>
              <Nav.Link href="#ft">Condiciones</Nav.Link>
              <Button className="nav-link-button" href="https://portalservicios.metlife.cl/login/indisa?utm_medium=Clinica&utm_source=Boton&utm_campaign=Banner_INDISA" target="_blank">Portal asegurados
              </Button>
            </Nav>
            
          </Navbar.Collapse>
        </Navbar>
      </div>

    );
}

export default Inicio
